import Store from '@/store';
import DictionariesProcesses from '@/store/services/DictionariesStoreService';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const { state } = Store;

    if (state.dictionaries.cryptocurrencies.length) {
      return resolve();
    }

    const dictionariesService = new DictionariesProcesses();

    return dictionariesService
      .fetchDictionaries()
      .then(() => resolve())
      .catch(reject);
  });
}
