export default {
  fiatSectionTitle: 'Available to spend',
  cryptoSectionTitle: 'Crypto balance',
  selectAccount: 'Select account',
  exchange: {
    requestsLink: 'Exchange requests',
    from: 'From:',
    to: 'To:',
    selectCurrency: 'Select currency',
    ratesTooltip: `Real-time rates.
    Service fee is deducted from successful conversions.`,
    proceed: 'Preview conversion',
    expectedOutput: 'Expected output:',
    willReceive: 'You will receive:',
    placeExchange: 'Place exchange ({counter} sec)',
  },
  statement: {
    emptyMessageText:
      'Select a filter above to generate your balance statement accordingly.',
    showStatement: 'Show statement',
    updateStatement: 'Update statement',
    summaryTitle: 'Balance summary',
    summaryNoteOne:
      'Displays the starting and ending balance of the selected currency in your INXY account.',
    summaryNoteTwo:
      'Changes to your balance are derived from transactions, which are summarized here and detailed below.',
    startingBalance: 'Starting balance on {date}',
    finalBalance: 'Final balance on {date}',
  },
  accounts: {
    descriptionOne:
      'Manage multiple accounts for better financial organization.',
    descriptionTwo: 'Easily separate funds for different purposes or streams.',
    addAccount: 'Add new account',
    editAccount: 'Edit account',
    editAccountInfo: 'Edit account information',
    accountPurpose: 'Account purpose',
    accountCreated: 'Account was created successfully',
    accountUpdated: 'Account information was updated successfully',
  },
};
