export interface SelectOption<T extends string = string> {
  label: string;
  value: T;
  disabled?: boolean;
}

export interface SelectOptionWithImage<T extends string = string>
  extends SelectOption<T> {
  image: string;
}

export enum Icon {
  Copy = 'Copy',
  BarCode = 'BarCode',
  Close = 'Close',
  Calendar = 'Calendar',
}

export enum ModalType {
  Small = 'small',
  Medium = 'medium',
  Service = 'service',
  Full = 'full',
}

export interface TabsNavigationElement<T = unknown> {
  label: string;
  name: T;
}

export enum TabsNavigationTypes {
  WithBackground = 'with-background',
}
