import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

import isFiatFlowActive from '@/app/fiat-flow/isFiatFlowActive';

export default [
  {
    path: '/dashboard',
    name: RouteName.Dashboard,
    component: () => import('@/views/ViewDashboard.vue'),
    meta: {
      layout: Layout.Default,
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (isFiatFlowActive()) {
        next({ name: RouteName.Balances });
      }

      next();
    },
  },
];
